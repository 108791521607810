import React from 'react';
import './index.scss';
import Footer from '@components/Footer';
import Header from '@components/Header';
import IndexLayout from '@layouts/index';
import Hero from '@page-components/home/Hero';
import CatalogColaborateAnalyze from '@page-components/home/CatalogColaborateAnalyze';
import Innovators from '@page-components/home/Innovators';
import Discover from '@page-components/home/Discover';
import Uniqueness from '@page-components/home/Uniqueness';
import Infrastructure from '@page-components/home/Infrastructure';
import Revolutionize from '@page-components/home/Revolutionize';

const Home = () => {
  return (
    <IndexLayout
      pageName="home"
      helmet={{
        title: 'TileDB • Designed for discovery',
        description:
          'TileDB is the foundational database designed for scientific discovery. Powered by shape-shifting arrays, TileDB resolves the complexity of multimodal data like population genomics, proteomics, single-cell and bioimaging so scientists and data teams can effectively glean biologically meaningful insights that drive drug and target discovery.',
      }}
    >
      <Header variant="transparent-white" />
      <main className="main TDB-Home">
        <Hero />
        <Revolutionize />
        <CatalogColaborateAnalyze />
        <Uniqueness />
        <Infrastructure />
        <Innovators />
        <Discover />
      </main>
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default Home;
