import React from 'react';
import './Innovators.scss';
import Typography from '@components/Typography';
import Container from '@components/Container';
import InlineButton from '@components/InlineButton';
import { Link } from '@reach/router';
import links from '@constants/links';
import SectionTitle from '../components/SectionTitle';
import Ray from './assets/Ray-Veeraghavan.png';
import quest from './assets/quest.svg';
import Sam from './assets/Sam-Cooper.png';
import phenomic from './assets/phenomic.svg';
import RayMobile from './assets/Ray-Veeraghavan-mobile.png';
import SamMobile from './assets/Sam-Cooper-mobile.png';

const Innovators: React.FC = () => {
  return (
    <div className="TDB-Home__innovators">
      <SectionTitle title="Loved by innovators" />
      <Container>
        <div className="TDB-Home__innovators__card">
          <div className="TDB-Home__innovators__card__section">
            <img className="author-img" src={Ray} alt="Ray Veeraghavan" />
            <div className="TDB-Home__innovators__card__section__graphic TDB-Home__innovators__card__section__graphic--left" />
            <div className="TDB-Home__innovators__card__section__inner">
              <img className="quest-logo-mobile" src={quest} alt="quest" />
              <div className="TDB-Home__innovators__card__section__inner__quote">
                <Typography className="TDB-Home__innovators__card__section__inner__quote__text" as="p" fontSize="body-lg">
                  ”The data mesh vision and rollout of data products is a multi-year disruptive, and yet, extremely rigorous undertaking in
                  Quest's data leadership journey. TileDB is uniquely positioned as a strategic player in our ecosystem. TileDB is a rare
                  find — simply put, they offer thought and execution partnership across all aspects of multi-omics, speak the language of
                  our end-users, and deliver a much simpler foundational data infrastructure, at the scale we wish to operate.”
                </Typography>
                <div className="TDB-Home__innovators__quote__author">
                  <img className="TDB-Home__innovators__author-avatar-mobile" src={RayMobile} alt="Ray Veeraghavan" />
                  <Typography fontSize="body-lg" fontWeight="bold" color="neutral-600" as="div">
                    Ray Veeraghavan
                  </Typography>
                  <Typography color="neutral-400" fontWeight="semi-bold" fontSize="body" as="div">
                    Global Head of Bioinformatics & Software
                  </Typography>
                </div>
              </div>
              <div className="TDB-Home__innovators__card__section__cta">
                <div>
                  <img className="quest-logo-desktop" src={quest} alt="quest" />
                </div>
                <Link to={links.case_studies.quest}>
                  <InlineButton className="TDB-Home__innovators__card__section__inner__cta__button" variant="light">
                    Read full case study
                  </InlineButton>
                </Link>
              </div>
            </div>
          </div>
          <div className="TDB-Home__innovators__card__section">
            <div className="TDB-Home__innovators__card__section__inner">
              <img className="phenomic-logo-mobile" src={phenomic} alt="phenomic" />
              <div className="TDB-Home__innovators__card__section__inner__quote">
                <Typography className="TDB-Home__innovators__card__section__inner__quote__text" as="p" fontSize="body-lg">
                  ”TileDB was the best database and platform out there for our cloud workflows and unique domain of single-cell research. Of
                  course, TileDB delivered the analysis speed, scale, and usability throughout our evaluations. What sets TileDB apart is
                  their single-cell biology team — they have walked in our shoes and are innovators in this field.”
                </Typography>
                <div className="TDB-Home__innovators__quote__author">
                  <img className="TDB-Home__innovators__author-avatar-mobile" src={SamMobile} alt="Sam Cooper" />
                  <Typography fontSize="body-lg" fontWeight="bold" color="neutral-600" as="div">
                    Sam Cooper
                  </Typography>
                  <Typography color="neutral-400" fontWeight="semi-bold" fontSize="body" as="div">
                    CTO and Co-Founder
                  </Typography>
                </div>
              </div>
              <div className="TDB-Home__innovators__card__section__cta">
                <div>
                  <img className="phenomic-logo-desktop" src={phenomic} alt="phenomic" />
                </div>
                <Link to={links.case_studies.phenomic_ai}>
                  <InlineButton className="TDB-Home__innovators__card__section__inner__cta__button" variant="light">
                    Read full case study
                  </InlineButton>
                </Link>
              </div>
            </div>
            <img className="author-img" src={Sam} alt="Sam Cooper" />
            <div className="TDB-Home__innovators__card__section__graphic TDB-Home__innovators__card__section__graphic--right" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Innovators;
