import React from 'react';
import './SectionTitle.scss';
import Typography from '@components/Typography';
import Container from '@components/Container';

interface SectionTitle {
  title: string;
  description?: string;
}

const SectionTitle: React.FC<SectionTitle> = (props) => {
  const { title, description } = props;
  return (
    <div className="TDB-Home__section-title-wrapper">
      <Container>
        <div className="TDB-Home__section-title">
          <div className="TDB-Home__section-title__left-line" />
          <Typography color="neutral-900" fontSize="heading-1" fontWeight="bold" align="center" as="h3">
            {title}
          </Typography>
          <div className="TDB-Home__section-title__right-line" />
        </div>
      </Container>
      {description && (
        <Container className="TDB-Home__section-title__description">
          <div className="TDB-Home__section-title__description__wrapper">
            <Typography align="center" fontSize="body-lg" color="neutral-800" as="p">
              {description}
            </Typography>
          </div>
        </Container>
      )}
    </div>
  );
};

export default SectionTitle;
