import React from 'react';
import './Infrastructure.scss';
import Container from '@components/Container';
import Typography from '@components/Typography';
import SectionTitle from '../components/SectionTitle';
import collaboration from './assets/collaboration.png';

const Infrastructure: React.FC = () => {
  return (
    <div className="TDB-Home__infrastructure">
      <SectionTitle title="Solidify your data infrastructure" />
      <Container>
        <div className="TDB-Home__infrastructure__cards">
          <div className="TDB-Home__infrastructure__card TDB-Home__infrastructure__card__collaboration">
            <img className="TDB-Home__infrastructure__card__collaboration__image" src={collaboration} alt="graphic" />
            <Typography
              className="TDB-Home__infrastructure__card__collaboration__title"
              as="p"
              color="neutral-900"
              fontSize="heading-4"
              fontWeight="bold"
            >
              Faster collaboration, faster discovery
            </Typography>
            <Typography className="TDB-Home__infrastructure__card__collaboration__description" as="p">
              United, data and science teams work faster together to identify promising opportunities and eliminate false positives. Faster
              time to discovery.
            </Typography>
          </div>
          <div className="TDB-Home__infrastructure__card TDB-Home__infrastructure__card__simpler-stack">
            <div className="TDB-Home__infrastructure__card__simpler-stack__text">
              <Typography
                className="TDB-Home__infrastructure__card__simpler-stack__title"
                as="p"
                color="neutral-900"
                fontSize="heading-4"
                fontWeight="bold"
              >
                Simpler stack
              </Typography>
              <Typography className="TDB-Home__infrastructure__card__simpler-stack__description" as="p">
                Multi-omics, tables, files and other modalities built-in.
              </Typography>
            </div>
            <div className="TDB-Home__infrastructure__card__simpler-stack__graphic" />
          </div>
          <div className="TDB-Home__infrastructure__card TDB-Home__infrastructure__card__performance">
            <div className="TDB-Home__infrastructure__card__performance__text">
              <Typography
                className="TDB-Home__infrastructure__card__performance__title"
                as="p"
                color="neutral-900"
                fontSize="heading-4"
                fontWeight="bold"
              >
                Unprecedented performance
              </Typography>
              <Typography className="TDB-Home__infrastructure__card__performance__description" as="p">
                Cloud-optimized storage, structure and indexing via arrays, serverless and elastic compute.
              </Typography>
            </div>
            <div className="TDB-Home__infrastructure__card__performance__graphic" />
          </div>
          <div className="TDB-Home__infrastructure__card TDB-Home__infrastructure__card__lower-cost">
            <Typography
              className="TDB-Home__infrastructure__card__lower-cost__title"
              as="p"
              color="neutral-900"
              fontSize="heading-4"
              fontWeight="bold"
            >
              Much lower cost
            </Typography>
            <Typography className="TDB-Home__infrastructure__card__lower-cost__description" as="p">
              Fewer pieces of software, more cost-effective compute due to speed, less maintenance and data engineering.
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Infrastructure;
