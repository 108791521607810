import React, { useEffect, useState } from 'react';
import './CatalogColaborateAnalyze.scss';
import Typography from '@components/Typography';
import Container from '@components/Container';
import classNames from 'classnames';
import Button from '@components/v2/Button';
import links from '@constants/links';
import SectionTitle from '../components/SectionTitle';
import catalogImg from './assets/catalog.png';
import annotateImg from './assets/annotate.png';
import organizeImg from './assets/organize.png';
import searchImg from './assets/search.png';
import controlImg from './assets/control.png';
import shareImg from './assets/share.png';
import complyImg from './assets/comply.png';
import monitorImg from './assets/monitor.png';
import previewImg from './assets/preview.png';
import queryImg from './assets/query.png';
import visualizeImg from './assets/visualize.png';
import scaleImg from './assets/scale.png';
import analyzeGradient from './assets/analyze-gradient.jpg';
import catalogGradient from './assets/catalogo-gradient.jpg';
import collaborateGradient from './assets/collaborate-gradient.jpg';

enum Section {
  Catalog = 'Catalog',
  Collaborate = 'Collaborate',
  Analyze = 'Analyze',
}

enum InnerTabs {
  Catalog = 'Catalog',
  Annotate = 'Annotate',
  Organize = 'Organize',
  Search = 'Search',
  Control = 'Control',
  Share = 'Share',
  Comply = 'Comply',
  Monitor = 'Monitor',
  Preview = 'Preview',
  Query = 'Query',
  Visualize = 'Visualize',
  Scale = 'Scale',
}

interface InnerTabContent {
  text: string;
  functionalities: string[];
  image: string;
  academyLink: string;
}

function getSectionTabs(section: Section): InnerTabs[] {
  const tabs = {
    [Section.Catalog]: [InnerTabs.Catalog, InnerTabs.Annotate, InnerTabs.Organize, InnerTabs.Search],
    [Section.Collaborate]: [InnerTabs.Control, InnerTabs.Share, InnerTabs.Comply, InnerTabs.Monitor],
    [Section.Analyze]: [InnerTabs.Preview, InnerTabs.Query, InnerTabs.Visualize, InnerTabs.Scale],
  };
  return tabs[section];
}

function getInnerTabContent(tab: InnerTabs): InnerTabContent {
  const contents: { [key in InnerTabs]: InnerTabContent } = {
    [InnerTabs.Catalog]: {
      text: 'Unify and catalog any scientific data file type — from genomic and single-cell to PDFs and CSVs — for discovery, all in one place.',
      functionalities: [
        'Upload files from your local machine or connect to your cloud storage, all through the UI.',
        'Ingest data in bulk programmatically using the TileDB Cloud APIs.',
        'Catalog datasets across multiple organizations without having to move or duplicate the physical data on cloud storage.',
      ],
      image: catalogImg,
      academyLink: links.academy.catalog,
    },
    [InnerTabs.Annotate]: {
      text: "Enrich your datasets with metadata and custom tags. Use TileDB's native vector search capabilities to ensure fast retrieval of the exact data you need.",
      functionalities: [
        'Annotate datasets with detailed, arbitrary metadata fields and custom tags for easier retrieval.',
        'Automatically capture dataset statistics and metadata upon ingestion.',
        "Index your catalogued files to build your organization's LLM-powered knowledge base.",
      ],
      image: annotateImg,
      academyLink: links.academy.catalog,
    },
    [InnerTabs.Organize]: {
      text: "Control data access at a project, team, or individual level with TileDB's flexible organizations and groups.",
      functionalities: [
        'Safely manage access to data across organizations, while maintaining FAIR data standards.',
        'Create new or map existing folder/file hierarchical structures.',
        'Organize collections of related and relevant files into groups for faster search.',
      ],
      image: organizeImg,
      academyLink: links.academy.groups,
    },
    [InnerTabs.Search]: {
      text: 'Using metadata and vector search, find the exact data you need in seconds, not hours.',
      functionalities: [
        'Filter search results by arbitrary metadata and custom tags.',
        'Build your own LLM chatbot dashboard to query against thousands of indexed files in natural language.',
        "Track changes over time by browsing previous versions of datasets to understand how and when they've changed.",
      ],
      image: searchImg,
      academyLink: links.academy.search,
    },
    [InnerTabs.Control]: {
      text: 'Define and manage fine-grained permissions to maintain security and control over your data.',
      functionalities: [
        'Govern access to all types from a single location, simplifying administration.',
        'Define precise user-level permissions and govern access for specific organizations and their datasets.',
        'Track permission changes and audit access history.',
      ],
      image: controlImg,
      academyLink: links.academy.organizations,
    },
    [InnerTabs.Share]: {
      text: 'Organize data internally for different teams and projects, and share across organizations too.',
      functionalities: [
        'Share only specific datasets with external collaborators with fine-grained access control at the asset level.',
        'Leverage user-defined federated queries to control access to only specific sub-sets of a dataset,  facilitating access while also protecting sensitive information.',
        'Discover, publish,  and collaborate on and use public data in the TileDB Marketplace, accelerating research and propelling your projects forward.',
      ],
      image: shareImg,
      academyLink: links.academy.share,
    },
    [InnerTabs.Comply]: {
      text: 'Built-in compliance protects sensitive information with the highest standards of data security and privacy.',
      functionalities: [
        'Confidently manage health data in compliance with HIPAA privacy rules.',
        'Trust in secure data processing and storage in a SOC-2 compliant system of record.',
        'Regular audits assure that TileDB maintains the highest security standards.',
      ],
      image: complyImg,
      academyLink: links.academy.comply,
    },
    [InnerTabs.Monitor]: {
      text: "Maintain a comprehensive activity monitoring across all the company's data.",
      functionalities: [
        'Track and review a detailed audit trail of user access events, including who accessed what data and when.',
        'View critical information on programmatic access, queries and writes, including statuses, logs and resource consumption.',
        'Monitor and manage the costs associated with queries run against catalogued datasets.',
      ],
      image: monitorImg,
      academyLink: links.academy.logging,
    },
    [InnerTabs.Preview]: {
      text: "Leverage TileDB's built-in preview widgets for scientific data types to interact with data directly on the catalog.",
      functionalities: [
        'Zoom into detail and toggle the colour saturation levels on your microscopic images.',
        'Browse and read standard document formats like PDFs, without opening an additional application.',
        "Combine the power of single cell and point cloud data in TileDB's new spatial transcriptomics viewer.",
      ],
      image: previewImg,
      academyLink: links.academy.preview,
    },
    [InnerTabs.Query]: {
      text: 'Access and query catalogued data, locally or in hosted environments.',
      functionalities: [
        'Launch and run Jupyter notebooks on TileDB, pre-configured with the necessary libraries and resources for your analysis.',
        'Access catalogued datasets via API, so you can continue working in your local development environment.',
        'Catalog and share notebooks to collaborate on analyses within team members.',
      ],
      image: queryImg,
      academyLink: links.academy.notebooks,
    },
    [InnerTabs.Visualize]: {
      text: 'Publish your own custom dashboards for others to interact with your results, all hosted on TileDB.',
      functionalities: [
        'Create interactive dashboards from your notebooks using popular tools like RStudio, Panel, Gradio and iPywidgets.',
        'Leverage indexed assets to power your own custom LLM RAG applications.',
        'Share visualizations with your team and track activity.',
      ],
      image: visualizeImg,
      academyLink: links.academy.dashboards,
    },
    [InnerTabs.Scale]: {
      text: 'Run serverless distributed operations at scale, efficiently and cost-effectively without managing infrastructure.',
      functionalities: [
        "Make use of TileDB's native array format to effectively range slice on a specific sub-set of rows rather than query an entire dataset.",
        'Ingest and query against massive amounts of data and a huge number of files in a distributed manner.',
        'Dynamically scale compute resources automatically to most efficiently execute your tasks.',
      ],
      image: scaleImg,
      academyLink: links.academy.scale,
    },
  };
  return contents[tab];
}

const CatalogColaborateAnalyze: React.FC = () => {
  const [section, setSection] = useState(Section.Catalog);
  const [selectedTab, setSelectedTab] = useState(InnerTabs.Catalog);
  useEffect(() => {
    // Every time the user changes section
    // we select the first tab of each section
    // which will update the content
    setSelectedTab(getSectionTabs(section)[0]);
  }, [section]);

  return (
    <>
      {/*
       * preload images
       */}
      <link rel="preload" as="image" href={analyzeGradient} />
      <link rel="preload" as="image" href={catalogGradient} />
      <link rel="preload" as="image" href={collaborateGradient} />
      <link rel="preload" as="image" href={annotateImg} />
      <link rel="preload" as="image" href={catalogImg} />
      <link rel="preload" as="image" href={complyImg} />
      <link rel="preload" as="image" href={monitorImg} />
      <link rel="preload" as="image" href={controlImg} />
      <link rel="preload" as="image" href={organizeImg} />
      <link rel="preload" as="image" href={previewImg} />
      <link rel="preload" as="image" href={queryImg} />
      <link rel="preload" as="image" href={scaleImg} />
      <link rel="preload" as="image" href={searchImg} />
      <link rel="preload" as="image" href={shareImg} />
      <link rel="preload" as="image" href={visualizeImg} />
      <div className="TDB-Home__cca">
        <SectionTitle
          title="Catalog, Collaborate, Analyze in one place"
          description="Stop struggling with data silos. TileDB gives teams a single, secure platform to manage, analyze, and maximize the value of all their R&D data."
        />
        <Container>
          <div>
            <div className="TDB-Home__cca__tabs">
              <Tab
                onClick={() => setSection(Section.Catalog)}
                active={section === Section.Catalog}
                title="Catalog and search"
                description="Upload and catalog your data into a single system of record, all data types unified."
                icon={
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.2504 5.63049C17.2504 4.95801 16.8465 4.00899 14.923 3.31298C13.674 2.86013 12.029 2.61108 10.2898 2.61108C8.5506 2.61108 6.90564 2.86014 5.65661 3.31298C3.73309 4.00913 3.32922 4.95801 3.32922 5.63049V16.2575C3.32922 16.93 3.73309 17.879 5.65454 18.575C6.90554 19.0278 8.5506 19.2769 10.2898 19.2769C12.0291 19.2769 13.674 19.0278 14.923 18.575C16.8466 17.8788 17.2504 16.93 17.2504 16.2575L17.2504 5.63049ZM10.2899 4.17964C13.8192 4.17964 15.6818 5.25806 15.6818 5.63059C15.6818 6.00302 13.8192 7.08144 10.2899 7.08144C6.76062 7.08144 4.89799 6.00302 4.89799 5.63049C4.89799 5.25806 6.76062 4.17964 10.2899 4.17964ZM4.89799 7.62263C5.14335 7.74654 5.39612 7.85527 5.65486 7.94814C6.90575 8.39511 8.55077 8.65003 10.2898 8.65003C12.0288 8.65003 13.674 8.3951 14.9248 7.94814C15.1842 7.85527 15.4376 7.74668 15.6835 7.62263V10.8459C15.6835 11.2185 13.8287 12.2969 10.2916 12.2969C6.75467 12.2969 4.89971 11.2185 4.89971 10.8459L4.89799 7.62263ZM10.2899 17.7083C6.76066 17.7083 4.89799 16.6299 4.89799 16.2574V12.8381C5.14335 12.962 5.39612 13.0707 5.65486 13.1634C6.90575 13.6164 8.55077 13.8655 10.2898 13.8655C12.0288 13.8655 13.674 13.6164 14.923 13.1636V13.1634C15.1825 13.0707 15.4358 12.962 15.6818 12.8381V16.2575C15.6818 16.6299 13.8192 17.7084 10.2899 17.7084L10.2899 17.7083ZM9.70175 11.3559H10.8782C11.1585 11.3559 11.4174 11.2064 11.5574 10.9636C11.6975 10.721 11.6975 10.422 11.5574 10.1794C11.4174 9.93678 11.1584 9.7873 10.8782 9.7873H9.70175C9.42152 9.7873 9.16263 9.93678 9.02258 10.1794C8.88253 10.422 8.88253 10.721 9.02258 10.9636C9.16263 11.2064 9.42155 11.3559 9.70175 11.3559ZM9.70165 14.8851H10.8781C11.1583 14.8851 11.4173 15.0346 11.5573 15.2773C11.6974 15.5199 11.6974 15.8189 11.5573 16.0615C11.4173 16.3042 11.1584 16.4537 10.8781 16.4537H9.70165C9.42145 16.4537 9.16253 16.3042 9.02248 16.0615C8.88243 15.8189 8.88243 15.52 9.02248 15.2773C9.16253 15.0346 9.42141 14.8851 9.70165 14.8851Z"
                    />
                  </svg>
                }
              />
              <Tab
                onClick={() => setSection(Section.Collaborate)}
                active={section === Section.Collaborate}
                title="Collaborate securely"
                description="Eliminate information silos and govern all data access in one place."
                icon={
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.7213 12.6107H15.2333C15.2928 12.9083 15.3524 13.2059 15.3524 13.5035V16.301C15.3524 16.5391 15.2928 16.7176 15.2333 16.8962H17.7332C18.0308 16.8962 18.3284 16.5986 18.3284 16.301V14.2178C18.3284 13.3845 17.6141 12.6107 16.7213 12.6107Z" />
                    <path d="M17.0785 9.69418C17.0785 8.50376 16.1261 7.55143 14.9952 7.55143C13.8643 7.55143 12.912 8.50376 12.912 9.69418C12.912 10.8846 13.8643 11.8369 14.9952 11.8369C16.1261 11.8369 17.0785 10.8251 17.0785 9.69418Z" />
                    <path d="M4.75767 12.6107H3.26966C2.37685 12.6107 1.6626 13.3249 1.6626 14.2178V16.301C1.6626 16.5986 1.9602 16.8962 2.2578 16.8962H4.69815C4.63863 16.7771 4.63863 16.5391 4.63863 16.3605V13.563C4.57911 13.2059 4.63863 12.9083 4.75767 12.6107Z" />
                    <path d="M7.01946 9.69418C7.01946 8.50376 6.06713 7.55143 4.93624 7.55143C3.80534 7.55143 2.85301 8.50376 2.85301 9.69418C2.85301 10.8846 3.80534 11.8369 4.93624 11.8369C6.06713 11.8369 7.01946 10.8251 7.01946 9.69418Z" />
                    <path d="M12.2573 11.5988H7.79323C6.72186 11.5988 5.82905 12.4917 5.82905 13.563V16.3605C5.82905 16.7176 6.06713 16.9557 6.42425 16.9557H13.5667C13.9239 16.9557 14.1619 16.7176 14.1619 16.3605V13.563C14.1619 12.4917 13.3287 11.5988 12.2573 11.5988Z" />
                    <path d="M9.93597 10.4679C11.3645 10.4679 12.5549 9.27753 12.5549 7.78952C12.5549 6.3015 11.3645 5.11108 9.93597 5.11108C8.50748 5.11108 7.31706 6.3015 7.31706 7.78952C7.31706 9.27753 8.44796 10.4679 9.93597 10.4679Z" />
                  </svg>
                }
              />
              <Tab
                onClick={() => setSection(Section.Analyze)}
                active={section === Section.Analyze}
                title="Analyze"
                description="The resources you need to do scientific analysis at scale."
                icon={
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.08808 6.83613L6.60184 11.5303C6.51027 11.8196 6.24164 12.0163 5.93806 12.0163H2.35884C1.97424 12.0163 1.6626 11.7046 1.6626 11.3201C1.6626 10.9356 1.97422 10.624 2.35884 10.624H5.42844L7.41568 4.34712C7.61991 3.70167 8.53203 3.69819 8.74138 4.34199L12.2957 15.2747L14.038 11.0547C14.1456 10.7941 14.3997 10.6242 14.6815 10.6242H17.6323C18.0168 10.6242 18.3284 10.9358 18.3284 11.3203C18.3284 11.7047 18.0168 12.0165 17.6323 12.0165H15.1472L12.8615 17.5532C12.6146 18.1514 11.7561 18.1183 11.5559 17.5028L8.08808 6.83613Z" />
                  </svg>
                }
              />
            </div>
            <div className={`TDB-Home__cca__container TDB-Home__cca__container--${section}`}>
              <div className="TDB-Home__cca__inner-tabs">
                {getSectionTabs(section).map((t) => (
                  <InnerTab key={t} onClick={() => setSelectedTab(t)} active={selectedTab === t}>
                    {t}
                  </InnerTab>
                ))}
              </div>
              <div className="TDB-Home__cca__content">
                <ContentTemplate {...getInnerTabContent(selectedTab)} />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

interface TabProps {
  title: string;
  description: string;
  icon: JSX.Element;
  active: boolean;
  onClick: () => void;
}

const Tab: React.FC<TabProps> = (props) => {
  const { title, description, icon, active, onClick } = props;

  return (
    <button className={classNames('TDB-Home__cca__tab', { 'TDB-Home__cca__tab--active': active })} onClick={onClick}>
      <div className="TDB-Home__cca__tab__icon">{icon}</div>
      <Typography className="TDB-Home__cca__tab__title" color="neutral-600" fontWeight="semi-bold" fontSize="subheading-2">
        {title}
      </Typography>
      <Typography className="TDB-Home__cca__tab__description" color="neutral-600" fontWeight="medium" fontSize="body">
        {description}
      </Typography>
    </button>
  );
};

interface InnerTabProps {
  active: boolean;
  onClick: () => void;
}

const InnerTab: React.FC<InnerTabProps> = (props) => {
  const { children, active, onClick } = props;

  return (
    <button className={classNames('TDB-Home__cca__inner-tab', { 'TDB-Home__cca__inner-tab--active': active })} onClick={onClick}>
      <Typography className="TDB-Home__cca__inner-tab__text" color="neutral-600" fontWeight="medium" fontSize="body-lg">
        {children}
      </Typography>
    </button>
  );
};

interface ContentTemplateProps {
  text: string;
  image: string;
  functionalities: string[];
  academyLink: string;
}

const ContentTemplate: React.FC<ContentTemplateProps> = (props) => {
  const { text, image, functionalities = [], academyLink } = props;

  return (
    <div className="TDB-Home__cca__content-template">
      <div className="TDB-Home__cca__content-template__text">
        <Typography fontSize="body-lg" fontWeight="medium" color="neutral-600">
          {text}
        </Typography>
        {functionalities.map((f) => (
          <div key={f} className="TDB-Home__cca__content-template__functionality">
            <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M24.0365 44C26.7664 44 29.3352 43.4771 31.7428 42.4314C34.1505 41.3856 36.2754 39.9447 38.1176 38.1085C39.9538 36.2724 41.3947 34.1444 42.4405 31.7246C43.4802 29.3048 44 26.7299 44 24C44 21.264 43.4771 18.6922 42.4314 16.2845C41.3856 13.8708 39.9416 11.7428 38.0994 9.90059C36.2572 8.05837 34.1262 6.61438 31.7063 5.56863C29.2865 4.52288 26.7117 4 23.9818 4C21.2458 4 18.674 4.51983 16.2663 5.55951C13.8526 6.59918 11.7276 8.04013 9.89147 9.88236C8.05533 11.7185 6.61438 13.8434 5.56863 16.2572C4.52288 18.6648 4 21.2336 4 23.9635C4 26.6934 4.51984 29.2652 5.55951 31.679C6.59918 34.0927 8.04013 36.2177 9.88235 38.0538C11.7185 39.8839 13.8434 41.3218 16.2572 42.3675C18.6648 43.4072 21.2336 43.927 23.9635 43.927L24.0365 44ZM21.3461 34.0593C21.0056 34.0593 20.6986 33.9833 20.425 33.8313C20.1453 33.6793 19.8687 33.433 19.5951 33.0926L13.9316 26.1158C13.7857 25.9273 13.6702 25.7237 13.585 25.5048C13.4999 25.2798 13.4574 25.0549 13.4574 24.8299C13.4574 24.3678 13.6124 23.9818 13.9225 23.6717C14.2326 23.3555 14.6095 23.1974 15.0534 23.1974C15.3269 23.1974 15.5853 23.2582 15.8285 23.3798C16.0717 23.5014 16.3058 23.7112 16.5308 24.0091L21.2093 30.0556L30.3201 15.4546C30.6971 14.8527 31.1652 14.5518 31.7246 14.5518C32.1502 14.5518 32.5363 14.6855 32.8828 14.953C33.2233 15.2206 33.3935 15.5884 33.3935 16.0565C33.3935 16.2754 33.3388 16.4973 33.2294 16.7223C33.1199 16.9412 33.0074 17.1479 32.8919 17.3425L22.9147 33.0287C22.7019 33.351 22.4557 33.5911 22.176 33.7492C21.8903 33.9012 21.5863 33.9772 21.264 33.9772L21.3461 34.0593Z"
                fill="#0077FF"
              />
            </svg>
            <Typography color="neutral-600" fontSize="body-lg" fontWeight="medium">
              {f}
            </Typography>
          </div>
        ))}
        <a href={academyLink} target="_blank" rel="noreferrer">
          <Button
            variant="outline"
            icon={
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.4518 2.20157C16.9011 1.81357 16.196 1.71782 15.5618 1.94482L10.1358 3.89077C10.0456 3.92326 9.94667 3.92326 9.85642 3.89077L4.43493 1.94482C3.80044 1.71696 3.09475 1.81241 2.54344 2.20041C1.99227 2.58856 1.66449 3.22076 1.66504 3.89495V14.7878C1.66533 15.2144 1.79731 15.6304 2.04295 15.9792C2.28857 16.3277 2.636 16.5921 3.03757 16.7358L9.30189 18.982C9.75358 19.1438 10.2474 19.1438 10.6993 18.982L16.9614 16.7358C17.3625 16.5917 17.7095 16.3273 17.9548 15.9787C18.2 15.63 18.3317 15.2141 18.3317 14.7879V3.89499C18.3317 3.22094 18.0035 2.58919 17.452 2.2016L17.4518 2.20157ZM3.32105 14.7879V3.895C3.32076 3.75984 3.38646 3.6332 3.49707 3.55551C3.56595 3.50642 3.6484 3.4797 3.73302 3.47884C3.78168 3.47869 3.83005 3.48707 3.87583 3.50367L9.17127 5.40412V17.1768L3.59636 15.177C3.4316 15.1184 3.32143 14.9627 3.32099 14.7878L3.32105 14.7879ZM16.6755 14.7879C16.6754 14.9622 16.5662 15.1178 16.4022 15.177L10.8273 17.1768V5.40415L16.1227 3.50585H16.1229C16.2495 3.4608 16.39 3.48 16.4999 3.5574C16.6098 3.6348 16.6753 3.76057 16.6756 3.89501L16.6755 14.7879Z" />
              </svg>
            }
          >
            Learn more on Academy
          </Button>
        </a>
      </div>
      <div className="TDB-Home__cca__content-template__image" style={{ backgroundImage: `url(${image})` }} />
    </div>
  );
};

export default CatalogColaborateAnalyze;
